import { Link } from '@inertiajs/react'
import { buttonVariants } from '@/Components/ui/button.jsx'
import { ArrowRight } from 'lucide-react'
import { t } from 'i18next'

const GettingStarted = ({ size, className }) => {
    return (

        <Link
            className={buttonVariants({
                size: size,
                className: className,
            })}
            href={route('register')}
        >
            {t('buttons.startNow')}
            <ArrowRight className="h-5 w-5"/>
        </Link>
    )
}

export default GettingStarted;
