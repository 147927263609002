import Navbar from '@/Components/Navbar/Navbar.jsx'
import { Toaster } from '@/Components/ui/toaster.jsx'
import Footer from '@/Components/Footer.jsx'

const RootLayout = ({ children, auth }) => {
    return (
        <div className="grainy">
            <Toaster/>
            <Navbar auth={auth ?? {}}/>
            {children}
            <Footer/>
        </div>
    )
}

export default RootLayout
