import {Icons} from '@/Components/Icons.jsx'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const {t} = useTranslation()

	return (
		<footer className="bg-white mt-6">
			<div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
				<div className="sm:flex sm:items-center sm:justify-between">
					<a
						href="https://reminara.de/"
						className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
					>
						<Icons.logo className="w-8 h-8" alt="Reminara Logo"/>
						<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
							reminara
						</span>
					</a>
					<ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
						<li>
							<a href="#" className="hover:underline me-4 md:me-6">
								{t("footer.cookiePolicy")}
							</a>
						</li>
						<li>
							<a href="#" className="hover:underline me-4 md:me-6">
								{t("footer.conditions")}
							</a>
						</li>
						<li>
							<a href="#" className="hover:underline me-4 md:me-6">
								{t("footer.dataProtection")}
							</a>
						</li>
						<li>
							<a href="#" className="hover:underline me-4 md:me-6">
								{t("footer.imprint")}
							</a>
						</li>
					</ul>
				</div>
				<hr
					className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
				<span
					className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a
					href="https://reminara.de/" className="hover:underline">reminara™</a>. All Rights Reserved.</span>
			</div>
		</footer>
	)
}

export default Footer;
