import { Link } from '@inertiajs/react'
import MaxWidthWrapper from '@/Components/MaxWidthWrapper.jsx'
import { buttonVariants } from '@/Components/ui/button.jsx'
import UserAccountNav from '@/Components/Navbar/UserAccountNav.jsx'
import MobileNav from '@/Components/Navbar/mobileNavbar.jsx'
import { Icons } from '@/Components/Icons.jsx'
import { useTranslation } from 'react-i18next'
import GettingStarted from '@/Components/Buttons/GettingStarted.jsx'

const Navbar = ({ auth }) => {
    const user = auth.user
    const { t } = useTranslation()

    return (
        <nav
            className="sticky h-14 inset-x-0 top-0 z-30 w-full border-b border-gray-200 bg-white/75 backdrop-blur-lg transition-all">
            <MaxWidthWrapper>
                <div
                    className="flex h-14 items-center justify-between border-b border-zinc-200">
                    <div className="flex items-center flex-1">
                        <Link
                            href={route('home')}
                            className="flex justify-center items-center z-40"
                        >
                            <Icons.logo className="w-10 h-10 mr-2"/>
                            <span
                                className="self-center text-2xl font-semibold whitespace-nowrap">
                                reminara
                            </span>
                        </Link>
                    </div>

                    <MobileNav isAuth={!!user}/>

                    <div
                        className="hidden items-center flex-1 justify-center sm:flex">
                        {!user ? (
                            <>
                                <div className="flex justify-center space-x-4">
                                    <Link
                                        href={`${route('home')}#features`}
                                        className={buttonVariants({
                                            variant: 'ghost',
                                            size: 'sm',
                                        })}
                                    >
                                        {t('navbar.features')}
                                    </Link>
                                    <Link
                                        href={`${route('home')}#pricing`}
                                        className={buttonVariants({
                                            variant: 'ghost',
                                            size: 'sm',
                                        })}
                                    >
                                        {t('navbar.pricing')}
                                    </Link>
                                    <Link
                                        href={`${route('home')}#faq`}
                                        className={buttonVariants({
                                            variant: 'ghost',
                                            size: 'sm',
                                        })}
                                    >
                                        {t('navbar.faq')}
                                    </Link>
                                </div>
                            </>
                        ) : null}
                    </div>

                    <div
                        className="lg:flex space-x-4 justify-end items-center flex-1 hidden">
                        {!user ? (
                            <>
                                <Link
                                    href={route('login')}
                                    className={buttonVariants({
                                        variant: 'ghost',
                                        size: 'sm',
                                    })}
                                >
                                    {t('buttons.login')}
                                </Link>
                                <GettingStarted size="sm"/>
                            </>
                        ) : (
                            <>
                                <Link
                                    href={route('dashboard')}
                                    className={buttonVariants({
                                        variant: 'ghost',
                                        size: 'sm',
                                    })}
                                >
                                    {t('buttons.dashboard')}
                                </Link>

                                <UserAccountNav
                                    name={`${user.firstname} ${user.lastname}`}
                                    email={user.email ?? ''}
                                />
                            </>
                        )}
                    </div>
                </div>
            </MaxWidthWrapper>
        </nav>
    )
}

export default Navbar
