import { ArrowRight, Gem, Menu } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link, usePage } from '@inertiajs/react'
import { useTranslation } from 'react-i18next'

const MobileNav = ({ isAuth }) => {
    const [isOpen, setOpen] = useState(false)
    const {t} = useTranslation()

    const toggleOpen = () => setOpen((prev) => !prev)

    const pathname = usePage()

    useEffect(() => {
        if (isOpen) toggleOpen()
    }, [pathname])

    const closeOnCurrent = (href) => {
        if (pathname === href) {
            toggleOpen()
        }
    }

    return (
        <div className="sm:hidden">
            <Menu
                onClick={toggleOpen}
                className="relative z-50 h-5 w-5 text-zinc-700"
            />

            {isOpen ? (
                <div
                    className="fixed animate-in slide-in-from-top-5 fade-in-20 inset-0 z-0 w-full">
                    <ul className="absolute bg-white border-b border-zinc-200 shadow-xl grid w-full gap-3 px-10 pt-20 pb-8">
                        {!isAuth ? (
                          <>
                              <li>
                                  <Link
                                    onClick={() =>
                                      closeOnCurrent(route('register'))
                                    }
                                    className="flex items-center w-full font-semibold text-blue-600"
                                    href={route('register')}
                                  >
                                      {t('buttons.startNow')}
                                      <ArrowRight className="ml-2 h-5 w-5"/>
                                  </Link>
                              </li>
                              <li className="my-3 h-px w-full bg-gray-300"/>
                              <li>
                                  <Link
                                    onClick={() =>
                                      closeOnCurrent(route('login'))
                                    }
                                    className="flex items-center w-full font-semibold"
                                    href={route('login')}
                                  >
                                      {t('buttons.login')}
                                  </Link>
                              </li>
                              <li className="my-3 h-px w-full bg-gray-300"/>

                              <li>
                                  <Link
                                    className="flex items-center w-full font-semibold"
                                    href={`${route('home')}#features`}
                                  >
                                      {t('navbar.features')}
                                  </Link>
                              </li>
                              <li>
                                  <Link
                                    className="flex items-center w-full font-semibold"
                                    href={`${route('home')}#pricing`}
                                  >
                                      {t('navbar.pricing')}
                                  </Link>
                              </li>
                              <li>
                                  <Link
                                    className="flex items-center w-full font-semibold"
                                    href={`${route('home')}#faq`}
                                  >
                                      {t('navbar.faq')}
                                  </Link>
                              </li>
                          </>
                        ) : (
                          <>
                              <li>
                                  <Link
                                    onClick={() => {
                                        closeOnCurrent(
                                          route('profile.edit'))
                                    }}
                                    href={route('profile.edit')}
                                        className="flex items-center w-full font-semibold"
                                    >
                                        {t("navbar.user.profile.edit")}
                                    </Link>
                                </li>
                                <li className="my-3 h-px w-full bg-gray-300"/>
                                <li>
                                    <Link
                                        onClick={() =>
                                            closeOnCurrent(route('dashboard'))
                                        }
                                        className="flex items-center w-full font-semibold"
                                        href={route('dashboard')}
                                    >
                                        {t("dashboard.title")}
                                    </Link>
                                </li>
                                <li>
                                    {(
                                      <Link
                                        href={`${route('home')}#pricing`}
                                        className="flex items-center w-full font-semibold opacity-50 pointer-events-none	cursor-none"
                                      >
                                          {t("buttons.upgradeNow")}
                                          <Gem className="text-blue-500 h-4 w-4 ml-2"/>
                                      </Link>
                                    )}
                                </li>
                                <li className="my-3 h-px w-full bg-gray-300"/>
                                <li>
                                    <Link
                                        className="flex items-center w-full font-semibold"
                                        href={route('logout')}
                                        method="post"
                                        as="button"
                                    >
                                        Abmelden
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            ) : null}
        </div>
    )
}

export default MobileNav
