import { User } from 'lucide-react'

const style = '.cls-1{fill:none;stroke:#9acaff;stroke-miterlimit:10;stroke-width:2px;}'
const styleFacebook = '.st0{fill:url(#SVGID_1_);} .st1{fill:#FFFFFF;}'

export const Icons = {
    user: User,
    logo: (props) => (
        <svg id="Ebene_2" {...props} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 119.21 103.4">
            <defs>
                <style>{style}</style>
            </defs>
            <g id="Ebene_1-2">
                <path className="cls-1"
                      d="m94.88,13.02c-3.2,1.19-6.62,2.55-9.82,3.74l-.42.15"/>
                <path className="cls-1"
                      d="m74.85,31.98c3.07-4.82,6.86-10.38,9.94-15.19"/>
                <path className="cls-1"
                      d="m94.66,13.02c8.04,2.93,16.42,5.86,24.45,8.79"/>
                <path className="cls-1"
                      d="m101.68,17.95c4.08.65,8.17,1.3,12.25,1.94"/>
                <path className="cls-1"
                      d="m101.82,17.95c-3.8,1.32-7.61,2.64-11.41,3.96"/>
                <line className="cls-1" x1="94.92" y1="13.21" x2="87.78"
                      y2="27.09"/>
                <path className="cls-1"
                      d="m84.72,16.96c.99,3.21,2.24,7.17,3.23,10.38"/>
                <path className="cls-1"
                      d="m74.72,32.1c8.84-3.29,18.1-6.75,26.93-10.04"/>
                <path className="cls-1"
                      d="m101.68,18.12c-.02.97,0,3.97-.03,4.94"/>
                <path className="cls-1"
                      d="m101.92,21.98c5.75.03,11.5.05,17.25.08"/>
                <path className="cls-1"
                      d="m114.41,22.07c-2.65,1.45-6.18,3.37-8.83,4.83"/>
                <path className="cls-1"
                      d="m105.67,26.81c-1.23,1.55-2.84,3.52-4.07,5.07"/>
                <path className="cls-1"
                      d="m101.66,22.86c0,2.61-.04,7.03-.05,9.64"/>
                <path className="cls-1"
                      d="m101.56,31.81c-8.58.05-17.55.09-26.13.14"/>
                <path className="cls-1"
                      d="m101.6,32.44c.05,4.67.08,9.72.13,14.39"/>
                <path className="cls-1"
                      d="m101.55,46.75c-8.62-4.77-18.04-9.99-26.66-14.77"/>
                <path className="cls-1"
                      d="m101.55,46.71c-13.12,8.81-26.53,17.69-39.66,26.5"/>
                <path className="cls-1"
                      d="m86.69,65.43c4.94-6.21,10.17-12.59,15.11-18.79"/>
                <path className="cls-1"
                      d="m86.8,38.67c-.01,8.57-.02,18.07-.04,26.64"/>
                <path className="cls-1"
                      d="m86.67,38.67c-3.73,2.05-7.87,4.29-11.6,6.34"/>
                <path className="cls-1"
                      d="m74.89,31.82c.06,10.69.08,21.74.14,32.42"/>
                <path className="cls-1"
                      d="m86.82,65.34c-8.35,2.61-16.74,5.32-25.09,7.92"/>
                <path className="cls-1"
                      d="m61.84,73.23c-1.68.4-3.85.9-5.53,1.3"/>
                <path className="cls-1"
                      d="m56.38,74.57c-2.6-1.27-5.36-2.62-7.96-3.89"/>
                <path className="cls-1"
                      d="m61.65,73.34c-1.25,7.92-2.73,16.65-3.99,24.57"/>
                <path className="cls-1"
                      d="m58.99,73.94c-2.14,9.37-4.43,18.78-6.57,28.15"/>
                <path className="cls-1"
                      d="m56.45,74.47c-2.58,7.81-5.41,16.3-7.99,24.11"/>
                <path className="cls-1"
                      d="m54.54,73.76c-4.76,9.68-9.84,19.46-14.6,29.14"/>
                <path className="cls-1"
                      d="m52.79,72.94c-5.26,7.41-11.01,15.12-16.28,22.53"/>
                <path className="cls-1"
                      d="m50.5,71.69c-7.15,8.37-15.02,16.91-22.18,25.28"/>
                <path className="cls-1"
                      d="m48.42,70.47c-7.81,7.32-15.99,14.95-23.8,22.27"/>
                <path className="cls-1"
                      d="m16.88,93.28c2.36-.13,5.46-.42,7.82-.54"/>
                <path className="cls-1"
                      d="m24.62,92.73c1.02,1.22,2.61,3.2,3.63,4.41"/>
                <path className="cls-1"
                      d="m28.25,97.14c2.16-.49,6.23-1.36,8.4-1.85"/>
                <path className="cls-1"
                      d="m36.76,95.35c.8,2.36,2.26,5.52,3.06,7.88"/>
                <path className="cls-1"
                      d="m39.87,103.16c2.41-1.36,6.28-3.3,8.69-4.66"/>
                <path className="cls-1"
                      d="m48.54,98.37c1,1.03,2.93,2.97,3.92,4"/>
                <path className="cls-1"
                      d="m52.42,102.37c1.45-1.22,3.9-3.43,5.35-4.64"/>
                <path className="cls-1"
                      d="m16.83,93.28c15.9-13.69,32.52-27.98,48.43-41.67"/>
                <path className="cls-1"
                      d="m48.31,70.72c.38-3.58,1.02-7.63,1.4-11.21"/>
                <path className="cls-1"
                      d="m74.71,64.38c-7.52.09-17,.05-24.51.14"/>
                <path className="cls-1"
                      d="m65.01,51.61c2.96,3.83,6.95,9.08,9.91,12.91"/>
                <path className="cls-1"
                      d="m42.97,9.79c10.41,7.19,21.51,14.84,31.92,22.03"/>
                <path className="cls-1"
                      d="m45.84,27.68c9.49,1.38,19.63,2.92,29.12,4.3"/>
                <path className="cls-1"
                      d="m65.15,51.71c2.9-6.1,6.84-13.57,9.74-19.67"/>
                <path className="cls-1"
                      d="m65.08,25.14c.02,8.21-.03,18.22,0,26.42"/>
                <path className="cls-1"
                      d="m52.46,16.37c3.87,11.09,8.57,24,12.45,35.09"/>
                <path className="cls-1"
                      d="m42.88,9.61c1.04,5.69,1.92,12.31,2.96,18"/>
                <path className="cls-1"
                      d="m45.99,27.71c2.44,6.39,5.72,13.82,8.16,20.21"/>
                <path className="cls-1"
                      d="m54.03,47.81c1.07.41,5.81,2.2,6.46,2.45,0,0,.05.02.05.02,1,.39,3.8,1.21,4.41,1.44"/>
                <path className="cls-1"
                      d="m49.6,59.7c3.41-2.71,7.99-6.5,11.4-9.21"/>
                <path className="cls-1"
                      d="m46.49,57.67c3.39-2.61,7.68-5.9,11.06-8.52"/>
                <path className="cls-1"
                      d="m43.48,55.02c3.24-2.08,7.59-5.23,10.83-7.31"/>
                <path className="cls-1"
                      d="m40.54,53.39c3.92-2.54,8.64-5.32,12.56-7.86"/>
                <path className="cls-1"
                      d="m37.93,50.7c4.45-2.47,9.72-5.24,14.17-7.71"/>
                <path className="cls-1"
                      d="m35.09,47.15c5.06-2.15,10.83-4.38,15.89-6.53"/>
                <path className="cls-1"
                      d="m32.99,43.52c5.49-1.67,11.63-3.46,17.13-5.13"/>
                <path className="cls-1"
                      d="m30.66,39.88c5.98-1.31,12.53-2.61,18.51-3.92"/>
                <path className="cls-1"
                      d="m26.81,36.25c6.97-.85,14.47-1.83,21.44-2.67"/>
                <path className="cls-1"
                      d="m23.11,32.33c7.63-.63,16.27-1.35,23.89-1.98"/>
                <path className="cls-1"
                      d="m19.12,28.19c8.67-.19,18.25-.31,26.91-.51"/>
                <path className="cls-1"
                      d="m15.85,24.05c9.61.27,20.02.65,29.63.92"/>
                <path className="cls-1"
                      d="m13.28,19.89c10.34.82,21.33,1.91,31.67,2.73"/>
                <path className="cls-1"
                      d="m10.55,16.12c11.04,1.38,23.09,2.98,34.13,4.36"/>
                <path className="cls-1"
                      d="m6.52,11.74c12.32,2.08,25.48,4.45,37.8,6.54"/>
                <path className="cls-1"
                      d="m5.88,7.73c12.37,2.59,25.85,5.6,38.22,8.19"/>
                <path className="cls-1"
                      d="m5.26,2.96c12.35,3.05,25.84,6.82,38.18,9.87"/>
                <path className="cls-1"
                      d="m16.63,1.03c8.52,2.81,17.91,6.02,26.43,8.83"/>
                <path className="cls-1"
                      d="m16.69,1.03c-2-.22-4.52-.54-6.52-.75"/>
                <path className="cls-1"
                      d="m10.25.28c.72,1.1,2.22,3.55,2.93,4.65"/>
                <path className="cls-1"
                      d="m5.39,2.96c-1.6.3-3.65.76-5.25,1.06"/>
                <path className="cls-1"
                      d="m.14,4.02c1.59.93,4.28,2.8,5.87,3.74"/>
                <path className="cls-1"
                      d="m6.11,7.73c-1.43.42-3.93,1.37-5.35,1.78"/>
                <path className="cls-1"
                      d="m.82,9.47c1.49.47,4.31,1.84,5.8,2.31"/>
                <path className="cls-1"
                      d="m3.35,13.24c.88-.34,2.42-1.12,3.3-1.46"/>
                <path className="cls-1"
                      d="m3.31,13.17c1.97.73,5.46,2.25,7.43,2.98"/>
                <path className="cls-1"
                      d="m7.6,18.04c.88-.52,2.26-1.38,3.14-1.89"/>
                <path className="cls-1"
                      d="m7.54,18.04c1.49.44,4.3,1.41,5.79,1.86"/>
                <path className="cls-1"
                      d="m10.28,21.87c.79-.5,2.41-1.54,3.2-2.04"/>
                <path className="cls-1"
                      d="m10.33,21.82c1.45.59,4.18,1.69,5.63,2.28"/>
                <path className="cls-1"
                      d="m12.69,26.4c.97-.58,2.9-1.71,3.87-2.29"/>
                <path className="cls-1"
                      d="m12.79,26.4c1.69.45,4.76,1.34,6.45,1.8"/>
                <path className="cls-1"
                      d="m19.57,28.18c-.82.62-2.49,1.83-3.3,2.45"/>
                <path className="cls-1"
                      d="m16.27,30.63c1.91.4,5.35,1.26,7.26,1.66"/>
                <path className="cls-1"
                      d="m20.23,34.67c.9-.57,2.63-1.82,3.52-2.4"/>
                <path className="cls-1"
                      d="m20.23,34.67c1.79.39,4.79,1.19,6.58,1.58"/>
                <path className="cls-1"
                      d="m23.94,38.47c.93-.64,2.51-1.68,3.44-2.32"/>
                <path className="cls-1"
                      d="m23.94,38.47c1.8.31,5.08,1.05,6.88,1.36"/>
                <path className="cls-1"
                      d="m27.64,42.76c.88-.8,2.4-2.13,3.28-2.93"/>
                <path className="cls-1"
                      d="m27.78,42.81c1.43.12,4.13.52,5.56.64"/>
                <path className="cls-1"
                      d="m30.64,46.25c.71-.72,2.25-2.21,2.96-2.92"/>
                <path className="cls-1"
                      d="m30.64,46.25c1.11.15,3.72.64,4.83.78"/>
                <path className="cls-1"
                      d="m33.96,49.57c.33-.69,1.21-1.91,1.54-2.6"/>
                <path className="cls-1"
                      d="m33.87,49.51c.99.21,3.49.86,4.48,1.07"/>
                <path className="cls-1"
                      d="m36.76,53.27c.44-.69,1.33-2.22,1.77-2.91"/>
                <path className="cls-1"
                      d="m36.76,53.27c.82-.06,3.21.05,4.03-.01"/>
                <path className="cls-1"
                      d="m39.57,55.42c.25-.54.97-1.62,1.23-2.16"/>
                <path className="cls-1"
                      d="m39.51,55.42c1.03-.11,3.22-.39,4.25-.5"/>
                <path className="cls-1"
                      d="m42.45,57.88c.44-.88,1.15-2.39,1.59-3.27"/>
                <path className="cls-1"
                      d="m42.45,57.79c1.08-.08,3.19-.16,4.27-.25"/>
                <path className="cls-1"
                      d="m46.2,59.95c.22-.66.52-1.96.74-2.62"/>
                <path className="cls-1"
                      d="m46.16,59.95c.74-.06,2.78-.14,3.53-.21"/>
            </g>
        </svg>
    ),
    twitter: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 24 24"
             xmlSpace="preserve" {...props}>
            <path
                d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"/>
        </svg>
    ),
    google: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="32" width="32" {...props}>
            <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"/>
            <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"/>
            <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"/>
            <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"/>
            <path d="M1 1h22v22H1z" fill="none"/>
        </svg>
    ),
    facebook: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" height="800" width="1200"
             viewBox="-100 -100 1500 1500" {...props}>
            <path
                d="M1365.333 682.667C1365.333 305.64 1059.693 0 682.667 0 305.64 0 0 305.64 0 682.667c0 340.738 249.641 623.16 576 674.373V880H402.667V682.667H576v-150.4c0-171.094 101.917-265.6 257.853-265.6 74.69 0 152.814 13.333 152.814 13.333v168h-86.083c-84.804 0-111.25 52.623-111.25 106.61v128.057h189.333L948.4 880H789.333v477.04c326.359-51.213 576-333.635 576-674.373"
                fill="#1877f2"/>
            <path
                d="M948.4 880l30.267-197.333H789.333V554.609C789.333 500.623 815.78 448 900.584 448h86.083V280s-78.124-13.333-152.814-13.333c-155.936 0-257.853 94.506-257.853 265.6v150.4H402.667V880H576v477.04a687.805 687.805 0 00106.667 8.293c36.288 0 71.91-2.84 106.666-8.293V880H948.4"
                fill="#fff"/>
        </svg>
    ),
    linkedin: (props) => (
        <svg viewBox="0 0 72 72" width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
                    fill="#007EBB"/>
                <path
                    d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
                    fill="#FFF"/>
            </g>
        </svg>
    ),

}
